<template>
  <base-dialog customActivator :title="$t('confirmOrder')">
    <template #custom-activator="{ events: { handleOpenDialog } }">
      <v-alert
        text
        :value="checkMinimumOrder"
        type="info"
        dense
        border="left"
        class="mt-3"
      >
        {{ $t("minimumAmount") }} {{ minimumOrder }} €
      </v-alert>
      <BaseButton
        keyTextButtonTranslate="finishOrder"
        :disabled="
          disableFinishOrderBtn || checkMinimumOrder || checkOrderSchedule
        "
        :handlerClick="() => (conditionValid ? handleOpenDialog() : () => {})"
      />
      <v-alert
        text
        :value="disableFinishOrderBtn && conditionValid"
        type="info"
        dense
        border="left"
        class="mt-3"
      >
        {{ $t("badSettingOrder") }}
      </v-alert>
    </template>
    <template #content="{ events: { dialog } }">
      <InfoOrder
        :priceDomicileService="priceDomicileService"
        @finishOrderEvent="handlerFinishOrder"
      />

      <SelectMethodPay
        v-if="dialog"
        class="mt-5"
        @setMethodPaySelected="setMethodPaySelected"
      />

      <FormRedsys v-if="formRedsysData" :formData="formRedsysData" />
      <FormMonei v-if="formMoneiData" :formData="formMoneiData" />
    </template>
    <template
      #actions="{ events: { handleCloseDialog } }"
      class="flex-column flex-sm-row flex-wrap justify-space-between"
    >
      <base-btn
        color="blue-grey"
        class="btn-action my-2 mx-0 white--text"
        @click="handleCloseDialog"
      >
        {{ $t("back") }}
      </base-btn>
      <base-btn
        color="success"
        class="btn-action my-2 mx-0"
        :loading="loading"
        :disabled="loading || disableContinueOrderBtn"
        @click="handlerFinishOrder"
      >
        {{ $t("continue") }}
      </base-btn>
    </template>
  </base-dialog>
</template>
<script>
import { i18n } from "@/plugins/i18n";
import {
  orderTypeName,
  orderTypeStoreGetters,
  orderTypeStoreActions,
} from "@/store/modules/orderType/constNames.js";
import { TYPE_PAYMENT } from "./enums";
import {
  getErrorPayloadForSnackbar,
  getIsKioskSetting,
  formatPrice,
} from "@/helpers";
import {
  settingCtrl,
  paymentRedsysCtrl,
  paymentMoneiCtrl,
  shoppingCartCtrl,
} from "@/controllers";
import { useSnackbar } from "@/utils";
import { checkPaymentRoutesName } from "@/router/names";

export default {
  name: "FinishOrderBtn",
  components: {
    BaseButton: () => import("@/components/shared/BaseButtons/Index"),
    FormRedsys: () => import("@/components/paymentRedsys/FormRedsys"),
    FormMonei: () => import("@/components/paymentMonei/FormMonei"),
    SelectMethodPay: () => import("./SelectMethodPay"),
    InfoOrder: () => import("./InfoOrder"),
  },
  props: {
    conditionValid: { type: Boolean, default: true },
    priceDomicileService: { type: Number, default: 0 },
    scheduleOrderTime: { type: String, default: "" },
  },
  data() {
    return {
      loading: false,
      formRedsysData: null,
      formMoneiData: null,
      minimalAmountDomicile: "0.0",
      amountOtherTipIsInvalid: false,
      methodPaySelected: null,
    };
  },
  provide() {
    return {
      handleAmountOtherTipIsInvalid: this.handleAmountOtherTipIsInvalid,
    };
  },
  async mounted() {
    await this.getMinimalAmountDomicile();
  },
  methods: {
    handleAmountOtherTipIsInvalid(value) {
      this.amountOtherTipIsInvalid = value;
    },
    setMethodPaySelected(methodPaySelected) {
      this.methodPaySelected = methodPaySelected;
    },
    async handlerFinishOrder() {
      try {
        if (!this.conditionValid) return;
        this.loading = true;
        let order = {
          ...this.getOrderToSend,
          credit_card: this.methodPaySelected === TYPE_PAYMENT.CARD,
        };
        if (this.orderTypeIsToHomeOrToPickUp) {
          /*if (this.scheduleOrderTime === "") {
            this.$store.commit("user/OPEN_SNACKBAR", {
              succes: false,
              msg: this.$t("noAvailability"),
            });
            this.$router.push({ name: "Menu" });
            return;
          }*/
          order.shift_time = this.scheduleOrderTime;
        }
        this.checkDifferenceHoursForTable(order);

        this.methodPaySelected === TYPE_PAYMENT.CASH
          ? await this.payCashProcess(order)
          : await this.payCardProcess(order);
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
      this.loading = false;
    },
    checkDifferenceHoursForTable(order) {
      if (this.orderTypeIsToHomeOrToPickUp) return;
      const timeSaved = moment(
        localStorage.getItem("time") || new Date().getTime()
      );
      const now = moment();
      const difference = now.diff(timeSaved, "hours");
      if (difference > 2 || !order.qr) {
        this.$store.commit("user/OPEN_SNACKBAR", {
          msg: i18n.t("scanQrAgain"),
          success: false,
        });
        return this.$router.replace({ name: "ScanQr" });
      }
    },
    async payCashProcess(order) {
      const { data, message } = await shoppingCartCtrl.handleSendOrder(order);
      if (data && message) {
        this.$router.replace({
          name: checkPaymentRoutesName.INDEX.name,
          params: {
            requestId: data.id,
            orderStatus: "borrador",
          },
        });
        const snackbar = useSnackbar();
        snackbar.dispatchOpenSnackbar(message, i18n.t("successRequest"));
        const orderType =
          this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
        orderType !== orderTypeName.LOCAL &&
          !this.isKiosk &&
          this.$store.dispatch(orderTypeStoreActions.REMOVE_ORDER_TYPE);
        this.$store.dispatch("cart/clearCart");
      }
    },
    async payCardProcess(order) {
      try {
        const { use_monei } = await settingCtrl.getPaymentCardUse();
        const executePlatformPayload = {
          order,
          useMonei: use_monei,
          ctrl: use_monei ? paymentMoneiCtrl : paymentRedsysCtrl,
        };
        await this.executeCardPlatform(executePlatformPayload);
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
    },
    async executeCardPlatform({ order, useMonei, ctrl }) {
      try {
        const { data } = await ctrl.handleSendOrder(order);
        if (useMonei) this.formMoneiData = data;
        else this.formRedsysData = data;
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
    },
    async getMinimalAmountDomicile() {
      try {
        const { minimal_amount_domicile } =
          await settingCtrl.getDomicilePickupSetting();

        minimal_amount_domicile &&
          (this.minimalAmountDomicile = minimal_amount_domicile);
      } catch (error) {
        this.getMinimalAmountDomicile();
      }
    },
    formatPrice,
  },
  computed: {
    isKiosk() {
      return getIsKioskSetting;
    },
    getOrderToSend() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TO_SEND];
    },
    disableFinishOrderBtn() {
      const { qr } = this.$store.getters[orderTypeStoreGetters.GET_ORDER_LOCAL];

      return (
        !this.IHaveSelectedAnOrderType ||
        (this.orderTypeIsToHome && !this.IHaveSelectedAnAddress) ||
        (!this.orderTypeIsToHomeOrToPickUp && !qr) ||
        !this.conditionValid
      );
    },
    optionTipSelectedIsOther() {
      return this.$store.getters["cart/getTipInfo"].option === "other";
    },
    disableContinueOrderBtn() {
      return (
        (this.optionTipSelectedIsOther && this.amountOtherTipIsInvalid) ||
        !this.methodPaySelected
      );
    },
    IHaveSelectedAnOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    IHaveSelectedAnAddress() {
      return this.$store.getters[orderTypeStoreGetters.GET_ADDRESS];
    },
    orderTypeIsToHomeOrToPickUp() {
      return this.orderTypeIsToHome || this.orderTypeIsToPickUp;
    },
    orderTypeIsToHome() {
      return this.IHaveSelectedAnOrderType === orderTypeName.AT_HOME;
    },
    orderTypeIsToPickUp() {
      return this.IHaveSelectedAnOrderType === orderTypeName.TO_PICK_UP;
    },
    minimumOrder() {
      return this.minimalAmountDomicile.replace(".", ",");
    },
    checkMinimumOrder() {
      const costFormat = parseFloat(
        this.formatPrice(
          this.orderTypeIsToHome
            ? +this.$store.getters["cart/totalPrice"] +
                this.priceDomicileService
            : +this.$store.getters["cart/totalPrice"]
        ).replace(",", ".")
      );
      const minimumOrderFormat = parseFloat(this.minimumOrder);
      return costFormat < minimumOrderFormat && this.orderTypeIsToHome;
    },
    checkOrderSchedule() {
      let orderType = this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
      return (
        this.scheduleOrderTime == "" &&
        (orderType == "AT_HOME" || orderType == "TO_PICK_UP")
      );
    },
  },
};
</script>

<style lang="sass">
.btn-action
  min-width: 107px !important
</style>
